import { TButton, TButtonProps } from '../buttons'
import { useIsManager, usePermissionCheck, useProfile } from '@front/model'
import { ScrollArea, Stack } from '@mantine/core'
import { Link, useLocation } from '@remix-run/react'
import { type Icon, type IconProps } from '@tabler/icons-react'
import { EntityId, isDefined, isNotDefined, PermissionType } from '@terros/common'
import { type ReactElement, type ForwardRefExoticComponent, type RefAttributes, ReactNode } from 'react'

export type NavbarCollapseProps = {
  open: boolean
  toggle: VoidFunction
}

type Props = {
  routes: PageRoute[]
  toggleBurger?: VoidFunction
} & Partial<NavbarCollapseProps>

export const Navigation = ({ routes, open, toggleBurger }: Props): ReactElement => {
  const collapsible = isDefined(open)
  const isCollapsed = collapsible && !open

  return (
    <ScrollArea pb={4}>
      <Stack className='relative' gap={5}>
        {routes.map((props) => {
          return <NavigationButton {...props} isCollapsed={isCollapsed} key={props.path} onClick={toggleBurger} />
        })}
      </Stack>
    </ScrollArea>
  )
}

type RouteVisibilityProps = {
  scope: Scope | undefined
  isManager: boolean
  isAdmin: boolean
  isTantalim: boolean
}

type NavigationButtonProps = Pick<TButtonProps, 'onClick'> &
  PageRoute & {
    isCollapsed: boolean
  }

const NavigationButton = (props: NavigationButtonProps): ReactNode => {
  const { path, matchFormat, label, scope, icon: Icon, isCollapsed, ...buttonProps } = props
  const { isManager } = useIsManager()
  const { isTantalim, isAdmin } = useProfile()
  const currentPath = useLocation().pathname

  const permissionVisible = useRoutePermissionVisible({ scope })
  const visible = isRouteVisible({ scope, isManager, isAdmin, isTantalim }) || permissionVisible

  if (!visible) return

  const isActive = matchFormat === 'exact' ? currentPath === path : currentPath.startsWith(path)
  return (
    <TButton
      buttonType={isCollapsed ? 'icon-rounded' : 'default'}
      color={isActive ? 'brand' : 'neutral'}
      component={Link}
      icon={Icon}
      justify='left'
      to={path}
      variant='ghost'
      {...buttonProps}
    >
      {label}
    </TButton>
  )
}

const useRoutePermissionVisible = ({ scope }: Pick<RouteVisibilityProps, 'scope'>): boolean => {
  const { data: allowed } = usePermissionCheck({
    targetId: typeof scope === 'object' ? scope.entityId : undefined,
    permissionType: typeof scope === 'object' ? scope.permissionType : 'company:list',
  })

  return Boolean(allowed)
}

const isRouteVisible = ({ scope, isManager, isAdmin, isTantalim }: RouteVisibilityProps): boolean | undefined => {
  if (scope === 'manager') return isManager || isAdmin
  if (scope === 'admin') return isAdmin
  if (scope === 'tantalim') return isTantalim
  return isNotDefined(scope)
}

type Scope =
  | 'manager'
  | 'admin'
  | 'tantalim'
  | {
      entityId: EntityId | undefined
      permissionType: PermissionType
    }

export type PageRoute = {
  path: string
  label: string
  matchFormat?: 'exact' | 'starts'
  icon: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>
  scope?: Scope
}
