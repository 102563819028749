import { TButton, type TButtonProps } from '../buttons'
import { type NavbarCollapseProps } from './Navigation'
import { IconLayoutSidebarLeftCollapse, IconLayoutSidebarRightCollapse } from '@tabler/icons-react'
import { cn } from '@util'
import { type ReactElement } from 'react'

type Props = NavbarCollapseProps & Omit<TButtonProps, 'buttonType' | 'icon' | 'pos' | 'variant' | 'onClick'>
export const NavigationCollapseButton = ({ open, toggle, className, ...props }: Props): ReactElement => {
  return (
    <TButton
      buttonType='icon-rounded'
      className={cn('absolute -right-4 bottom-20', className)}
      icon={open ? IconLayoutSidebarLeftCollapse : IconLayoutSidebarRightCollapse}
      onClick={toggle}
      pos='absolute'
      variant='secondary'
      {...props}
    />
  )
}
